
// import {i18n} from '@/i18n';
// import i18n from '@/vueI18n';
import { mapActions, mapGetters } from 'vuex'
export default {
    name:'app-home-contact-us',
    data(){
        return {
            name: null,
            emailAddress: null,
            phoneNumber: null,
            message: null,
            validName:false,
            validEmail: false,
            validPhone:false,
            contactUsRadiusName:true,
            contactUsRadiusEmail: true,
            contactUsRadiusPhone: true,
            disablePhone:false,

            submitNameFirstTime: true,            
            submitEmailAddressFirstTime: true,
            submitPhoneNumberFirstTime: true,
            submitMessageFirstTime: true,


        }
    },
    computed:{
        ...mapGetters({
            saveLoading: 'home/saveLoading'
        })
    },
    mounted(){
        this.$q.iconSet.field.error = "img:/images/invalid-email.png"
    },
    methods:{
        ...mapActions({
            doCreate:'home/doCreate'
        }),
        setNameFirstTime(){
            this.submitNameFirstTime = false            
        },
        setEmailAddressFirstTime(){
            this.submitEmailAddressFirstTime = false            
        },
        setPhoneNumberFirstTime(){
            this.submitPhoneNumberFirstTime = false            
        },
        async doSubmit(){
            await this.doCreate({
                name: this.name,
                email: this.emailAddress,
                phone: this.phoneNumber,
                message: this.message,
                deviceTokens: {
                    web:localStorage.getItem('language')
                }

            })
            this.name = null
            this.emailAddress = null
            this.phoneNumber = null
            this.message = null

            this.submitNameFirstTime = true            
            this.submitEmailAddressFirstTime = true
            this.submitPhoneNumberFirstTime = true
            this.submitMessageFirstTime = true


        },
        i18n(key, args) {
            return this.$t(key, args);
        },
        validateName(){
            if(this.submitNameFirstTime){
                 return true
            }
            if(this.name && this.name != ''){
                this.validName = true
                return true
            }
            else{
                this.validName = false
                this.contactUsRadiusName = false            
                return false
            }
         
        },
        validateEmail(email){
            if(this.submitEmailAddressFirstTime){
                 return true
            }
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if(re.test(String(email).toLowerCase())){
            this.validEmail = true
            return true
        }
        else{
            this.validEmail = false
            this.contactUsRadiusEmail = false            
            return false
        }  
        },
        validatePhone(val){
            if(this.submitPhoneNumberFirstTime){
                 return true
            }
            const phoneno = /^\d{11}$/.test(val)
            if (phoneno) {
                this.validPhone = true
                return true; 
            }
            else {
                this.validPhone = false 
                this.contactUsRadiusPhone = false
                return false;
            } 
           
            },
    }
}
