import { render, staticRenderFns } from "./home-header.vue?vue&type=template&id=1a027486&scoped=true&"
import script from "./home-header.vue?vue&type=script&lang=js&"
export * from "./home-header.vue?vue&type=script&lang=js&"
import style0 from "./home-header.vue?vue&type=style&index=0&id=1a027486&prod&lang=scss&scoped=true&"
import style1 from "./home-header.vue?vue&type=style&index=1&id=1a027486&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a027486",
  null
  
)

export default component.exports
import {QField,QImg,QTab,QInput} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QField,QImg,QTab,QInput})
