
// import {i18n} from '@/i18n';
// import { mapActions } from 'vuex'
import ImageBackground from '@/modules/home/components/image-background.vue';
export default {
  name:'app-home-header',
  props:['displayTitle','displayTitleAfterDrugIndex','searchDrugs'],
  data(){
    return{
      // imageBg:"https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fhome-header-bg.webp?alt=media&token=0ab8db38-2d40-4128-9909-65da6323e666",
      // title:this.i18n('drugDeal.common.theBest'),
      // subtitle1:this.i18n('drugDeal.common.medicalReference'),
      // subtitle2:this.i18n('drugDeal.common.youNeed'),
      // subtitle3:this.i18n('drugDeal.common.inOne'),
      // subtitle4:this.i18n('drugDeal.common.place'),
    }
  },
  components:{
        [ImageBackground.name]: ImageBackground
    },
  methods:{
    // ...mapActions({
    //     setHeaderBg:'layout/setHeaderBg',
    //     setFooter:'layout/setFooter',
    //     setLoginHeader:'home/setLoginHeader'
    // })

    i18n(key, args) {
      return this.$t(key, args);
    },
  
  },
  mounted(){
    //  this.language = localStorage.getItem('language')

        // this.setHeaderBg('bg-transparent')
        // this.setNavbar('services')
        // this.setFooter(false)
  },
   computed:
  {
      isRTL() {
      return localStorage.getItem('language') == 'ar'
        },
        rowsPerPageOptions () {
        return [ 2 ]
      },
      imageBg(){
        return "https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fhome-header-bg.webp?alt=media&token=0ab8db38-2d40-4128-9909-65da6323e666"
      },
      title(){
        return this.i18n('drugDeal.common.theBest')
      },
      subtitle1(){
        return this.i18n('drugDeal.common.medicalReference')
      },
      subtitle2(){
        return this.i18n('drugDeal.common.youNeed')
      },
      subtitle3(){
        return this.i18n('drugDeal.common.inOne')
      },
      subtitle4(){
        return this.i18n('drugDeal.common.place')
      },
  },
  async created(){
    // this.filterSearch = this.searchDrugs
     
  }
}
