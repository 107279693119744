
// import {i18n} from '@/i18n';
import { mapGetters } from "vuex";
import i18n from "@/vueI18n";
export default {
  name: "app-home-download",
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    goToDownloadApp(website) {
      // const lang = localStorage.getItem('language')
      if (website == "app-store") {
        window
          .open(
            `https://apps.apple.com/eg/app/drug-deal/id1565732393`,
            "_blank"
          )
          .focus();
      } else {
         window
          .open(
            `https://play.google.com/store/apps/details?id=com.runprof.drugdeal`,
            "_blank"
          )
      }

      // window.open(`https://web.drugdealapp.com/${lang}/`, '_blank').focus();
    },
  },
  computed: {
    ...mapGetters({
      is_screen_lg: "layout/is_screen_lg",
      is_screen_md: "layout/is_screen_md",
      is_screen_xs: "layout/is_screen_xs",
      is_screen_sm: "layout/is_screen_sm",
    }),
    isRTL() {
      return i18n.locale === "ar";
    },
    download_desc() {
      return this.i18n("drugDeal.download.downloadDesc");
    },
  },
};
