
// import { getLanguageCode } from '@/i18n';
import { mapActions } from 'vuex';
import i18n from '@/vueI18n'
// import { i18n } from '@/i18n';
export default {
  name: 'app-home-about',

  data() {
    return {
      slide: 1,
      autoplay: true
    };
  },
  computed: {
    isRTL(){
            return i18n.locale == 'ar'
        },
    // isRTL() {
    //   return getLanguageCode() == 'ar'
    //   // return localStorage.getItem('language') == 'ar'
    // },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
      setNavbar:'layout/setNavbar'
    }),
    doGoToAbout(){
      this.setNavbar('about')
      let lang = localStorage.getItem('language')
      this.$router.push(`/${lang}/about`)
    }
  }
};
