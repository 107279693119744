
import ServicesItems from '@/modules/services/components/services-items.vue';
// import { i18n } from '@/i18n';
import i18n from '@/vueI18n'
import { mapGetters } from 'vuex';
export default {
    name:'app-home-services',
    data(){
        return{

        }
    },
    components:{
        [ServicesItems.name]: ServicesItems,
    },
    computed:{
        ...mapGetters({
            drugIndexHomeSearch:'home/drugIndexHomeSearch'
        }),
         isRTL(){
            return i18n.locale == 'ar'
        },
    },
    methods:{
       
        openLoginDialog(){
            this.$emit('login')
        },
        i18n(key, args) {
            return this.$t(key, args);
        },
        doGoToServices(){
            // this.setNavbar('services')
            // console.log("hena el navbar",this.getNavbar)
            let lang = localStorage.getItem('language')
            this.$router.push(`/${lang}/services`)
        }
    },
    
}
