
// import { i18n } from '@/i18n';
import HomeHeader from '@/modules/home/components/home-header.vue';
import HomeServices from '@/modules/home/components/home-services.vue';
import HomeAbout from '@/modules/home/components/home-about.vue';
import HomeDownload from '@/modules/home/components/home-download.vue';
import HomeContactUs from '@/modules/home/components/home-contact-us.vue';
// import HomeFeatures from '@/modules/home/components/home-features.vue';
import HomeFooter from '@/modules/home/components/home-footer.vue';
import { mapActions, mapGetters } from 'vuex';
import WelcomeEmail from './welcome-email.vue';
// import i18n from '@/vueI18n'
// import i18n from "@/vueI18n";


// import meta from '../../../utils/meta.js'
// import meta from '../../../utils/meta.js'

export default {
  name: 'app-home-page',
  props:['lang','displayTitle','searchDrugs','displayContent','displayTitleAfterDrugIndex'],

  components: {
    [WelcomeEmail.name]: WelcomeEmail,
    [HomeHeader.name]: HomeHeader,
    [HomeServices.name]: HomeServices,
    [HomeAbout.name]: HomeAbout,
    [HomeDownload.name]: HomeDownload,
    [HomeContactUs.name]: HomeContactUs,
    [HomeFooter.name]: HomeFooter,
  },

  created() {


    document.title =
            // this.i18n('app.title') +
            // ' | ' +
            this.i18n('drugDeal.common.mainPageTitle') 

    if (this.isMobile) {
      this.collapseMenu();
    }
  },

  data() {
    return {
        // metaTags: {
        //   description: 'this is home',
        //   // title: 'Home',
        //   url: 'web.drugdealapp.com/en',
        //   image: '#'
        // }
    }
  },
  //  meta,

    watch:{
      // displayTitleAfterDrugIndex(newval){
      //   // debugger
      // },
      lang(){
        document.title =
          // this.i18n('app.title') +
          // ' | ' +
          this.i18n('drugDeal.common.mainPageTitle') 
      }
  },
  computed: {
    // lang(){
    //   // debugger    
    //   return i18n.locale
    // },
    ...mapGetters({
      isMobile: 'layout/isMobile',
      loginHeader: 'home/loginHeader',
      drugIndexHomeSearch: 'home/drugIndexHomeSearch',
      currentUser: 'auth/currentUser'
    })
  },

  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    login(){
      // console.log('login')
      this.setLoginHeader(true)
      // console.log('loginHeader',this.loginHeader)
    },
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
      setHeaderBg:'layout/setHeaderBg',
      setFooter:'layout/setFooter',
      setLoginHeader:'home/setLoginHeader'
    }),
    scrollHandler(scroll){
      if(scroll.position == 0 ){
        this.setHeaderBg('bg-transparent')
      }
      // if(this.drugIndexHomeSearch)  {
      //   this.setHeaderBg('bg-transparent')
      // }
      if(scroll.position > 0 && !this.currentUser){
        // debugger
        setTimeout(() => {
          this.setHeaderBg('bg-shadow')
        }, 1000)
        
      }





      // if(scroll.position == 0  && !this.drugIndexHomeSearch)  {
      //   console.log('scroll handler',this.drugIndexHomeSearch)
      //   this.setHeaderBg('bg-transparent')
      // }
      // if(this.drugIndexHomeSearch){ 
      //   // 
      //   this.setHeaderBg('bg-shadow q-py-lg')
      // }
      // else{
      //   this.setHeaderBg('bg-shadow')
      // }
    }
  },
  mounted(){
    this.setFooter(false)
    // console.log('home mounted######$$$$$',this.lang)
    // console.log('in mounted home',this.$route)
    // console.log('drug index',this.displayTitle)
    // console.log('drug index',this.searchDrugs)


  }
};
